<template >
   <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App'
}

</script>

<style>


/*
  [v-cloak] {
    display: none;
  }
*/
</style>
